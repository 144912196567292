body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.nav-link {
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 30px;
  margin-bottom: 30px;
  padding: 5px;
  color: #9da3ac;
  background-color: #ffffff;
}

.nav-link:hover {
  background-color: #4b92f0;
  color: #ffffff;
}

.bn-onboard-modal {
  font-family: Roboto;
  z-index: 4;
}
.form-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 20px #00000005;
  border-radius: 15px;
}

.token-table-header {
  position: relative;
  color: black;
  font-weight: 600;
  font-size: large;
}

.token-table-cell {
  background: azure;
}
.chip {
  background: rgb(40, 43, 219);
  border-radius: 20%;
  padding: 3;
  color: #ffffff;
  font-weight: 600;
}
.counter-chip {
  background: rgb(4, 104, 4);
  border-radius: 20%;
  width: 55px;
  height: 30px;
  color: #ffffff;
  font-weight: 800;
}
